

// Lenis Smooth Scroll
const lenis = new Lenis();

function raf(time: number) {
    lenis.raf(time);
    requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time) => {
    lenis.raf(time * 1000)
})

gsap.ticker.lagSmoothing(0);

const hamburgerBtn = document.querySelector("#hamburgerBtn");
const navbar = document.querySelector(".navbar");
const overlay = document.querySelector(".overlay");
const fadeElements = document.querySelectorAll(".has-fade");
const body = document.querySelector("body");

hamburgerBtn.addEventListener('click', () => {
    let open = navbar.classList.toggle("open");
    if (open) {
        body.classList.add("lock-scroll");
        fadeElements.forEach((element) => {
            element.classList.remove("fade-out");
            element.classList.add("fade-in");
        });
    } else {
        body.classList.remove("lock-scroll");
        fadeElements.forEach((element) => {
            element.classList.remove("fade-in");
            element.classList.add("fade-out");
        });
    }

})